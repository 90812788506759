import React from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { IconButton, InputBase, Paper, Stack, Typography } from '@mui/material';
import { Header } from '@fiji/common/src/types';
import DeleteForever from '@mui/icons-material/DeleteForever';
import Edit from '@mui/icons-material/Edit';
import {
    useCreateVirtualLoadMutation,
    useGetAllVirtualLoadsQuery,
    useUpdateVirtualLoadByIdMutation,
} from '@fiji/common/src/features/deviceManagement/deviceApi';
import { useTypedSelector } from '@fiji/common/src/app/store';
import { CustomTable } from 'components';
import { useDebounce, useIsMount } from 'hooks';
import { selectedOrg } from '@fiji/common/src/features/orgManagement/orgSlice';
import { AddVirtualModal } from '../../VirtualLoadDetails/AddVirtualModal';
import { useConfirm } from '@fiji/common/src/hooks';
import { useDispatch } from 'react-redux';
import {
    resetVirtualLoadPayload,
    setDefaultPayload,
} from '@fiji/common/src/features/deviceManagement/virtualLoadSlice';
import { setMessageContent } from '@fiji/common/src/features/common/commonSlice';
import { useParams } from 'react-router-dom';
import { DeleteVirtualModal } from 'pages/VirtualLoadDetails/DeleteVirtualModal';
import { CustomMenu } from '../../../components/CustomMenu';
import { DeviceActivating } from '@brightlayer-ui/icons-mui';

const ActionCell = (data: any, getActionsBtn: any): JSX.Element => (
    <IconButton id={'menu'} aria-label="settings">
        <CustomMenu menuList={getActionsBtn(data)} />
    </IconButton>
);

const getLoadNameCell = (data: any): JSX.Element => (
    <Typography className="word-break width-200">{data?.name}</Typography>
);

const getLoadTypeCell = (data: any): JSX.Element => <Typography>{data?.loadType?.name}</Typography>;

const getAssetNameCell = (data: any): JSX.Element => (
    <Typography className="word-break width-200">{data?.assetName}</Typography>
);

const getDescriptionCell = (data: any): JSX.Element => (
    <Typography className="word-break width-300">{data?.description}</Typography>
);

export const VirtualLoadsList = (): JSX.Element => {
    const dispatch = useDispatch();
    const deleteRef: any = React.useRef(null);
    const currentOrg: any = useTypedSelector(selectedOrg);
    const { deviceId } = useParams();
    const isMount = useIsMount();
    const { payload: updatePayload } = useTypedSelector((state) => state.virtualLoad);
    const [mode, setMode] = React.useState('');
    const [payload, setPayload]: any = React.useState({
        page: 0,
        size: 10,
        deviceId,
    });
    const [searchKey, setSearchKey] = React.useState<any>();

    const [, debouncedValue] = useDebounce(undefined, undefined, searchKey);
    const [updateVirtualLoad, { isLoading: isUpdating }] = useUpdateVirtualLoadByIdMutation();
    const [createVirtualLoad, { isLoading: isCreatingLoads, isSuccess }] = useCreateVirtualLoadMutation();

    const handleSubmit = async (id: string): Promise<void> => {
        if (!id) {
            const { error }: any = await createVirtualLoad({ ...updatePayload, deviceId: deviceId });
            if (!error) {
                dispatch(setMessageContent({ isOpen: true, message: 'Virtual Load Created Successfully.' }));
                dispatch(resetVirtualLoadPayload());
            }
        } else {
            const { error }: any = await updateVirtualLoad({ connectedLoadId: id, body: updatePayload });

            if (!error) {
                dispatch(setMessageContent({ isOpen: true, message: 'Virtual Load Updated Successfully.' }));
                dispatch(resetVirtualLoadPayload());
                onCancel();
            }
        }
    };
    const { onClick, onConfirm, isVisible, onCancel } = useConfirm(handleSubmit as any);

    const getActionsBtn = (data: any): any => ({
        id: 'profile-menu',
        menuGroups: [
            {
                title: 'Edit',
                icon: <Edit />,
                onClick: (): void => {
                    setMode('edit');
                    onClick(data?.id);
                    dispatch(
                        setDefaultPayload({
                            assetName: data?.assetName,
                            description: data?.description,
                            loadType: data?.loadType?.id,
                            name: data?.name,
                        })
                    );
                },
            },
            {
                title: 'Delete',
                icon: <DeleteForever />,
                onClick: (): any => {
                    deleteRef?.current?.onClick(data);
                },
            },
        ],
    });

    const handlePageChange = (page: any, size: any): void => {
        setPayload((prev: any) => ({ ...prev, page: page, size: size }));
    };
    const handleFilterChange = (filters: any, sortData?: any): void => {
        if (sortData && Object.keys(sortData)?.length) {
            setPayload((prev: any) => ({
                ...prev,
                page: 0,
                sort: sortData,
            }));
        }
    };

    React.useEffect(() => {
        if (isSuccess) {
            onCancel();
        }
    }, [isSuccess]);

    React.useEffect(() => {
        if (!isMount) {
            setPayload((prev: any) => ({
                ...prev,
                page: 0,
                searchKey: debouncedValue,
            }));
        }
    }, [debouncedValue]);

    const tableRows: Header[] = [
        {
            header: 'Load Name',
            isSortable: true,
            accessor: 'name',
            cell: (data): JSX.Element => getLoadNameCell(data),
            width: '20',
        },
        {
            header: 'Type of Load',
            accessor: 'loadType',
            cell: (data): JSX.Element => getLoadTypeCell(data),
            width: '20',
        },
        {
            header: 'Asset Name/Tag',
            accessor: 'assetName',
            cell: (data): JSX.Element => getAssetNameCell(data),
            width: '20',
        },
        {
            header: 'Description',
            accessor: 'description',
            cell: (data): JSX.Element => getDescriptionCell(data),
            width: '30',
        },
        {
            header: '',
            cell: (data): JSX.Element => ActionCell(data, getActionsBtn),
            width: '2%',
        },
    ];
    const {
        data: loads,
        isLoading,
        isFetching,
    } = useGetAllVirtualLoadsQuery(payload, { skip: !currentOrg?.id || !deviceId, refetchOnMountOrArgChange: true });

    return (
        <Stack p={3}>
            <Stack className="border-1 border-radius-4" direction={'row'} maxWidth={'256px'}>
                <IconButton type="button" className="padding-10" aria-label="search">
                    <SearchIcon />
                </IconButton>
                <InputBase
                    id="searchKey"
                    className="margin-left-10"
                    placeholder="Search"
                    value={searchKey}
                    inputProps={{ 'aria-label': 'search' }}
                    onChange={(e: any): void => setSearchKey(e?.target?.value)}
                />
            </Stack>

            <Paper className="margin-y-16 box-shadow">
                <CustomTable
                    total={loads?.data?.total}
                    data={loads?.data?.records}
                    isLoading={isFetching || isLoading || isCreatingLoads}
                    keyToTraverse="id"
                    headers={tableRows}
                    noDataFoundIcon={<DeviceActivating className="height-76 width-76 color-content" />}
                    noDataFoundTitle="No Loads Found"
                    noDataFoundButtonAction={(): void => {
                        setMode('add');
                        onClick();
                    }}
                    noDataFoundButtonText="Add Virtual Load"
                    isPagination
                    handlePageChange={handlePageChange}
                    handleFilterChange={handleFilterChange}
                    // noDataFoundButtonAction={(): void => navigate(`/${currentRealmName}/addOrEditUser`)}
                />
            </Paper>
            <AddVirtualModal
                mode={mode}
                isLoading={isUpdating}
                isVisible={isVisible}
                onCancel={onCancel}
                onConfirm={onConfirm}
            />
            <DeleteVirtualModal ref={deleteRef} />
        </Stack>
    );
};
